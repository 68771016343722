<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-button
      block
      :variant="$store.state.appConfig.layout.skin === 'dark'?'dark':''"
      class="d-xxl-none mb-1"
      @click="isHidden = !isHidden"
    >
      <div>

        <span class="text-black">{{ $t('guide') }}</span>
        <feather-icon
          variant="black"
          :class="$store.state.appConfig.layout.isRTL?'left-position':'right-position'"
          :icon="isHidden?'ChevronDownIcon':'ChevronUpIcon'"
          size="20"
        />
      </div>
    </b-button>
    <b-row>
      <b-col
        class="col-xl-7"
        xl="12"
        md="12"
        sm="12"
      >
        <b-card
          class="h-100 d-xxl-none"
          :class="
            (isHidden ? 'd-none' : '')"
        >
          <div class="d-md-flex text-center">
            <b-img
              fluid
              width="150"
              :src="isCryptoMode ? require('@/assets/images/image/5.webp') : require('@/assets/images/image/crypto5.png')"
            />
            <p class="align-self-center mt-1">
              <span v-if="isCryptoMode">
                {{ $t('doucumnetBankPage.bankImage1Description') }}
              </span>
              <span v-else>
                {{ $t('doucumnetBankPageWithoutCommitment.bankImage1Description') }}
              </span>
            </p>
          </div>
          <div
            class="d-md-flex text-center"
            dir="rtl"
          >
            <b-img
              fluid
              width="150"
              :src="isCryptoMode ? require('@/assets/images/image/1.webp') : require('@/assets/images/image/crypto1.png')"
            />
            <p class="align-self-center mt-1">
              <span v-if="isCryptoMode">
                {{ $t('doucumnetBankPage.bankImage2Description') }}
              </span>
              <span v-else>
                {{ $t('doucumnetBankPageWithoutCommitment.bankImage2Description') }}
              </span>
            </p>
          </div>
          <div class="d-md-flex text-center">
            <b-img
              fluid
              width="150"
              :src="isCryptoMode ? require('@/assets/images/image/6.webp') : require('@/assets/images/image/crypto6.png')"
            />
            <p class="align-self-center mt-1">
              <span v-if="isCryptoMode">
                {{ $t('doucumnetBankPage.bankImage3Description') }}
              </span>
              <span v-else>
                {{ $t('doucumnetBankPageWithoutCommitment.bankImage3Description') }}
              </span>
            </p>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card v-if="banks.length > 0">
      <h3>{{ $t('doucumnetBankPage.banksCard') }}</h3>
      <p>{{ $t('doucumnetBankPage.banksCardDesscription') }}</p>
      <b-button
        class="mt-1"
        variant="success"
        :to="`/${$i18n.locale}/dashboard`"
      >
        {{ $t('go_to_dashboard') }}
      </b-button>
      <hr>
      <b-row>
        <b-col
          v-for="bank in banks"
          :key="bank.id"
          sm="12"
          lg="4"
        >

          <div
            class="shadow p-1 my-1"
            style="border-radius: 8px;"
          >
            <b-card-text>
              <div
                class="d-flex justify-content-between align-items-center"
              >
                <span>{{ $t('status') }} : {{ $t(bank.status) }}</span>
                <feather-icon
                  :icon="(bank.status === 'pending'? 'CircleIcon' : bank.status === 'success' ? 'CheckIcon' : 'XCircleIcon')"
                  size="30"
                  class="mx-1"
                  :stroke="(bank.status === 'pending'? 'orange' : bank.status === 'success' ? 'green' : 'red')"
                /></div>
              <br>
              <span
                v-if="bank.document && bank.document.tickets[0] && bank.document.tickets[0].comment"
                class="d-block"
              >{{ $t('rejectDescription') }}: {{ bank.document.tickets[0].comment }}</span>
              <span
                v-if="bank.status !='rejected'"
                class="d-block"
              > {{ $t('description') }}: {{ $t(bank.status == 'success'?'doucumnetBankPage.bankSuccessDiscription':'doucumnetBankPage.bankPendingDiscription') }}</span>
              <br>
              <div class="d-flex justify-content-between flex-wrap">
                <span>{{ $t("number") }}: </span>
                <span>{{ bank.numbers }}</span>
              </div>
              <div class="d-flex justify-content-between flex-wrap">
                <span>{{ $t("currency") }}: </span>
                <span v-if="bank.currency">{{ bank.currency.currency }}</span>
              </div>
              <div class="d-flex justify-content-between flex-wrap">
                <span>{{ $t("iban") }}: </span>
                <span>{{ bank.iban }}</span>
              </div>
              <div class="d-flex justify-content-between flex-wrap">
                <span>{{ $t("expiration_date") }}: </span>
                <span>{{ bank.expiration_date }}</span>
              </div>
              <div
                v-if="bank.country"
                class="d-flex justify-content-between flex-wrap"
              >
                <span>{{ $t("country_name") }}: </span>
                <span>{{ bank.country.name }}</span>
              </div>
            </b-card-text>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-row class="my-2">
      <b-col
        class="col-xxl-5"
        xl="12"
        md="12"
        sm="12"
      >

        <b-card
          id="bankAcount_section"
          class="h-100"
          :title="$t('bank-account-title')"
        >
          <validation-observer
            ref="bankTab"
          >
            <!-- form -->
            <b-form class="mt-2">
              <b-col>
                <b-form-group
                  label-for="currency"
                  :label="$t('fiatWidraw.currency')"
                >
                  <validation-provider
                    #default="{ errors }"
                    vid="currency"
                    :name="$t('fiatWidraw.currency')"
                    rules="required"
                  >
                    <v-select
                      id="currency"
                      v-model="currency"
                      :options="currencies"
                      :placeholder="$t('fiatWidraw.select')"
                      label="from_currency"
                      track-by="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="country_name"
                  :label="$t('country_name')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('country_name')"
                    vid="country_name"
                    rules="required"
                  >
                    <v-select
                      id="country_name"
                      v-model="country"
                      :dir="!$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="countries"
                      :placeholder="$t('country_name')"
                      autocomplete="nope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                xl="12"
                sm="12"
                class="col-xxl-12"
              >
                <b-form-group
                  :label="$t('bank_account_card_number')"
                  label-for="number"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('bank_account_card_number')"
                    vid="number"
                    rules="required|numeric|length:16"
                  >
                    <b-form-input
                      v-model.trim="card_number"
                      name="number"
                      autocomplete="nope"
                      @keypress="getCitizenship === 'IR' ? onlyNumbers($event): AlphaNumeric($event)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                xl="12"
                sm="12"
                class="col-xxl-12"
              >
                <b-form-group
                  :label="$t('iban')"
                  label-for="iban"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('iban')"
                    vid="iban"
                    rules="required|ibanValidate"
                  >
                    <b-form-input
                      v-model.trim="iban"
                      name="iban"
                      autocomplete="nope"
                      @input="convertIBAN2Uppercase"
                    />
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                xl="12"
                sm="12"
                class="col-xxl-12"
              >
                <b-form-group
                  label-for="expiration-date"
                  :label="$t('bank_account_card_expiration')"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="expiration_date_ref"
                    :name="$t('bank_account_card_expiration')"
                    vid="expiration-date"
                    rules="required"
                  >
                    <date-picker
                      id="expiration-date"
                      v-model="expiration_date"
                      :color="primaryColor"
                      :locale="country.name === 'Iran' ? 'fa' : 'en'"
                      type="date"
                      :disabled="Object.keys(country).length === 0"
                      :placeholder="$t('bank_account_card_expiration')"
                      auto-submit
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-alert
                  class="px-1 py-1"
                  :show="country.name === 'Iran' && convertedExpiration"
                >
                  <div :class="$store.state.appConfig.layout.skin === 'dark' && 'text-white'">
                    <span>{{ $t('exipiration_data_in_gregorian') }}</span>
                    <span> : </span>
                    <span
                      dir="ltr"
                    >{{ convertedExpiration || '' }}</span>
                  </div>
                </b-alert>
              </b-col>
              <b-col
                xl="12"
                sm="12"
                class="col-xxl-12"
              >
                <!-- file_back input -->
                <b-form-group
                  :label="$t('bank_account_file_proof')"
                  label-for="file_back"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('file_back')"
                    vid="file_back"
                    :rules="'image|required|size:'+MAX_IMAGE_UPLOAD_SIZE"
                  >
                    <div class="row">
                      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <b-form-file
                          ref="file-back"
                          v-model="file_back"
                          accept="image/png, image/jpg, image/jpeg"
                          :placeholder="$t('no_file_chosen')"
                        />
                      </div>
                    </div>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  v-if="!pending"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  :disabled="banks[banks.length-1] && banks[banks.length-1].status== 'pending'"
                  @click="submit"
                >
                  {{ $t('bank_account_save_button') }}
                </b-button>

                <b-button
                  v-if="pending"
                  variant="primary"
                  class="mt-2 mr-1"
                  disabled
                >
                  <b-spinner
                    small
                    type="grow"
                  />
                  <span>{{ $t('loading') }}</span>
                </b-button>
                <div
                  v-if="banks[banks.length-1] && banks[banks.length-1].status == 'pending'"
                  class="mt-1"
                >
                  <b-alert
                    show
                    class="p-1"
                  >
                    <span :class="$store.state.appConfig.layout.skin === 'dark' && 'text-white'">{{ $t('yourDocumentIsUnderReview') }}</span>
                  </b-alert>
                </div>
                <div
                  v-if="isPreparingFiles"
                  class="text-center"
                >
                  <h5 class="mt-2">
                    {{ $t('preparingFiles') }}
                  </h5>
                  <b-spinner
                    variant="primary"
                    label="Spinning"
                  />
                </div>
                <div
                  v-if="uploadSize > 0"
                  class="text-center"
                >
                  <h5 class="mt-2">
                    {{ $t('uploading') }}
                  </h5>
                  <b-progress
                    :value="currentUpload"
                    :max="uploadSize"
                    show-progress
                    animated
                    dir="ltr"
                    height="20px"
                  />
                </div>
              </b-col>

            </b-form>
          </validation-observer>
        </b-card>
      </b-col>

      <b-col
        class="col-xxl-7"
        xl="12"
        md="12"
        sm="12"
      >
        <b-card class="h-100  d-none d-xxl-block">
          <div class="d-md-flex">
            <b-img
              fluid
              width="150"
              :src="isCryptoMode ? require('@/assets/images/image/5.webp') : require('@/assets/images/image/crypto5.png')"
            />
            <p class="align-self-center mt-1 mx-1">
              <span v-if="isCryptoMode">
                {{ $t('doucumnetBankPage.bankImage1Description') }}
              </span>
              <span v-else>
                {{ $t('doucumnetBankPageWithoutCommitment.bankImage1Description') }}
              </span>
            </p>
          </div>
          <div
            class="d-md-flex"
            :dir="$store.state.appConfig.layout.isRTL? 'ltr':'rtl'"
          >
            <b-img
              fluid
              width="150"
              :src="isCryptoMode ? require('@/assets/images/image/1.webp') : require('@/assets/images/image/crypto1.png')"
            />
            <p
              :dir="$store.state.appConfig.layout.isRTL? 'rtl':'ltr'"
              class="align-self-center mt-1 mx-1"
            >
              <span v-if="isCryptoMode">
                {{ $t('doucumnetBankPage.bankImage2Description') }}
              </span>
              <span v-else>
                {{ $t('doucumnetBankPageWithoutCommitment.bankImage2Description') }}
              </span>
            </p>
          </div>
          <div class="d-md-flex">
            <b-img
              fluid
              width="150"
              :src="isCryptoMode ? require('@/assets/images/image/6.webp') : require('@/assets/images/image/crypto6.png')"
            />
            <p class="align-self-center mt-1 mx-1">
              <span v-if="isCryptoMode">
                {{ $t('doucumnetBankPage.bankImage3Description') }}
              </span>
              <span v-else>
                {{ $t('doucumnetBankPageWithoutCommitment.bankImage3Description') }}
              </span>
            </p>
          </div>
        </b-card>
      </b-col>
    </b-row>

  </b-overlay>
</template>
<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BSpinner,
  BImg,
  BFormFile,
  BOverlay,
  BCardText,
  BAlert,
  BProgress,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import {
  required, size, numeric, max, length, ibanValidate,
} from '@validations'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import jalaali from 'moment-jalaali'
import DatePicker from 'vue-persian-datetime-picker'
import { alphanumeric } from '@/mixins/alphanumeric'
import showErrorMessages from '@/mixins/showErrorMessages'
import checkFileSize from '@/mixins/checkFileSize'
import BankApis from '@/services/apis/documents/bank'
import ProfileApis from '@/services/apis/profile'
import FormApis from '@/services/apis/form'
import FiatApis from '@/modules/crypto/services/apis/fiat'
import { resizeImage, toEnglishDigits } from '@/utils'
import moment from '@/libs/moment'

const fiat = new FiatApis()
const formData = new FormApis()
const profile = new ProfileApis()
const bank = new BankApis()
export default {
  components: {
    BImg,
    BForm,
    BRow,
    BCard,
    BFormFile,
    BButton,
    BCol,
    BFormInput,
    vSelect,
    BFormGroup,
    BSpinner,
    BOverlay,
    BCardText,
    DatePicker,
    BAlert,
    BProgress,
  },
  directives: {
    Ripple,
  },
  mixins: [alphanumeric, showErrorMessages, checkFileSize],
  data() {
    return {
      card_number: '',
      currency: '',
      currencies: [],
      file_back: null,
      iban: '',
      required,
      size,
      numeric,
      max,
      length,
      ibanValidate,
      pending: false,
      expiration_date: '',
      banks: [],
      show: false,
      isHidden: true,
      countries: [],
      country: '',
      isPreparingFiles: false,
      currentUpload: 0,
      uploadSize: 0,
    }
  },
  computed: {
    ...mapGetters(['getCitizenship']),
    convertedExpiration() {
      return jalaali(this.expiration_date, 'jYYYY/jMM/jDD').format('YYYY-MM-DD')
    },
    citizenship() {
      return this.$store.state.auth?.user?.citizenship?.name.toLowerCase()
    },
    primaryColor() {
      return process.env.VUE_APP_PRIMARY_COLOR
    },
    MAX_IMAGE_UPLOAD_SIZE() {
      return process.env.VUE_APP_MAX_IMAGE_UPLOAD_SIZE
    },
    isCryptoMode() {
      return process.env.VUE_APP_ACTIVE_MODULES.includes('CRYPTO')
    },
  },
  watch: {
    '$store.state.Messaging.event': {
      async handler(newVal) {
        if (newVal === 'document_bank') {
          await this.getData()
          this.$store.dispatch('Messaging/setEvent', '')
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.show = true
    formData.countryLists().then(res => {
      const result = res.data.results
      this.countries = result.map(item => ({
        id: item.id,
        name: item.name,
        // eslint-disable-next-line global-require, import/no-dynamic-require
        image: require(`@/assets/images/flags/${item.abbreviation}.png`),
      }))
    }).finally(() => {
      this.show = false
    })
  },

  async created() {
    await this.getData()
    await this.getCurrency()
    this.show = false
  },

  methods: {
    convertIBAN2Uppercase(event) {
      this.iban = event.toUpperCase()
    },
    async getCurrency() {
      await fiat.getCurrency().then(res => {
        // eslint-disable-next-line prefer-destructuring
        this.currencies = res.data.results.form.filter(item => !item.document_hide)
        if (res.data.results.form[0]) {
          // eslint-disable-next-line prefer-destructuring
          this.currency = res.data.results.form[0]
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async getData() {
      this.show = true
      await bank.getDataInfo().then(res => {
        // eslint-disable-next-line prefer-destructuring
        this.banks = res.data.results
      }).finally(() => {
        this.show = false
      })
    },
    async submit() {
      if (moment().diff(moment(this.convertedExpiration).format('YYYY-MM-DD'), 'days') >= 1) {
        await this.$refs.expiration_date_ref.applyResult({
          errors: [this.$t('this_card_is_expire')], // array of string errors
          valid: false, // boolean state
          failedRules: {}, // should be empty since this is a manual error.
        })
        return
      }
      if (await this.$refs.bankTab.validate()) {
        const imageSize = (process.env.VUE_APP_RESIZE_IMAGE_SIZE ? process.env.VUE_APP_RESIZE_IMAGE_SIZE : 3072) * 1024
        this.isPreparingFiles = true
        this.pending = true
        const data = {
          numbers: toEnglishDigits(this.card_number),
          file_proof: await resizeImage(this.file_back, imageSize),
          iban: toEnglishDigits(this.iban),
          expiration_date: this.convertedExpiration,
          currency_id: this.currency.from_currency_id,
          country_id: this.country.id,
        }
        this.isPreparingFiles = false
        await bank.SaveBankInfo(data, e => {
          this.uploadSize = e.total
          this.currentUpload = e.loaded
        }).then(res => {
          this.$swal({
            title: res.data.message,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
          this.getData()
          profile.get()
        }).finally(() => {
          this.pending = false
          this.uploadSize = 0
          this.currentUpload = 0
          this.isPreparingFiles = false
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        })
      }
    },
  },

  baseUrl() {
    return process.env.VUE_APP_BASE_IMAGE_URL
  },

}
</script>
<style>

.right-position{
  float: right;
}
.left-position{
  float: left;
}

</style>
@/modules/crypto/service/apis/fiat
